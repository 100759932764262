<template>
  <div>
    <div class="mb-10 bg-secondary p-8 rounded">
      <div class="blueFit fs-6">
        Seleziona dal menù a tendina una tipologia di anomalia e clicca su cerca
        per ottenere i risultati
      </div>
    </div>
    <div class="card shadow">
      <form autocomplete="off">
        <div
          class="pt-4 pb-4 pe-4 ps-4"
          @keyup.enter="$emit('getVerificheAnomalieList')"
        >
          <div class="row pb-4">
            <div class="col-sm-6 filter">
              <SelectInputEvento
                :options="VerificheAnomalieLookup"
                name="verifiche_anomalia"
                placeholder="Tipo anomalia"
                :value="id_procedura_di_verificaVerificheAnomalie"
                @changeSelect="
                  setFiltroAnomalie('filtroAnomalieVerificheAnomalie', $event);
                  setFiltroAnomalie(
                    'id_procedura_di_verificaVerificheAnomalie',
                    $event.id
                  );
                "
              />
            </div>
          </div>
          <div class="row pb-4">
            <div
              class="col-md-2 col-sm-6 filter"
              v-if="
                filtroAnomalieVerificheAnomalie &&
                filtroAnomalieVerificheAnomalie.filtro_per_disciplina
              "
            >
              <SelectInput
                :options="discipline"
                name="disciplina"
                placeholder="Disciplina"
                :value="id_disciplinaVerificheAnomalie"
                @changeSelect="
                  setFiltroAnomalie('id_disciplinaVerificheAnomalie', $event)
                "
              />
            </div>
            <div
              class="col-md-2 col-sm-6 filter"
              v-if="
                filtroAnomalieVerificheAnomalie &&
                filtroAnomalieVerificheAnomalie.filtro_per_circolo
              "
            >
              <SelectInput
                :options="comitati"
                name="tipo_qualifica"
                placeholder="Comitato"
                :value="id_comitatoVerificheAnomalie"
                @changeSelect="
                  setFiltroAnomalie('id_comitatoVerificheAnomalie', $event)
                "
              />
            </div>
            <div
              class="col-md-4 col-sm-6 filter"
              v-if="
                filtroAnomalieVerificheAnomalie &&
                filtroAnomalieVerificheAnomalie.filtro_per_circolo
              "
            >
              <div class="filter">
                <div class="dp__input_wrap">
                  <input
                    name="nome_societa"
                    type="text"
                    placeholder="Società"
                    class="form-control"
                    autocomplete="off"
                    @input="getSocieta($event.target.value)"
                    :value="nome_societaVerificheAnomalie"
                  />
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    class="dp__icon dp__clear_icon dp__input_icons"
                    @click="
                      resetSocieta();
                      setFiltroAnomalie('id_societaVerificheAnomalie', null);
                      setFiltroAnomalie('nome_societaVerificheAnomalie', '');
                    "
                  >
                    <path
                      d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                    ></path>
                    <path
                      d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                    ></path>
                  </svg>
                </div>
                <ListSocieta
                  :focusOnInput="true"
                  :list="societa"
                  @hideList="
                    inputFocus = null;
                    resetSocieta;
                  "
                  @selectedSocieta="
                    setFiltroAnomalie(
                      'id_societaVerificheAnomalie',
                      $event.value
                    );
                    setFiltroAnomalie(
                      'nome_societaVerificheAnomalie',
                      $event.name
                    );
                    resetSocieta();

                    inputFocus = null;
                  "
                ></ListSocieta>
              </div>
            </div>
            <div
              class="col-md-4 col-sm-6 filter"
              v-if="
                filtroAnomalieVerificheAnomalie &&
                filtroAnomalieVerificheAnomalie.filtro_per_persona
              "
            >
              <div class="dp__input_wrap">
                <input
                  name="nome_persona"
                  type="text"
                  placeholder="Persona"
                  class="form-control"
                  autocomplete="off"
                  @input="getTesserati($event.target.value)"
                  :value="nomePersonaVerificheAnomalie"
                />
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  class="dp__icon dp__clear_icon dp__input_icons"
                  @click="
                    resetTesserati();
                    setFiltroAnomalie('id_personaVerificheAnomalie', null);
                    setFiltroAnomalie('nomePersonaVerificheAnomalie', '');
                  "
                >
                  <path
                    d="M23.057 7.057l-16 16c-0.52 0.52-0.52 1.365 0 1.885s1.365 0.52 1.885 0l16-16c0.52-0.52 0.52-1.365 0-1.885s-1.365-0.52-1.885 0z"
                  ></path>
                  <path
                    d="M7.057 8.943l16 16c0.52 0.52 1.365 0.52 1.885 0s0.52-1.365 0-1.885l-16-16c-0.52-0.52-1.365-0.52-1.885 0s-0.52 1.365 0 1.885z"
                  ></path>
                </svg>
              </div>
              <ListTesserati
                :focusOnInput="true"
                :list="tesseratiList"
                @hideList="
                  inputFocus = null;
                  resetTesserati;
                "
                @selectedTesserato="
                  setFiltroAnomalie(
                    'id_personaVerificheAnomalie',
                    $event.value
                  );
                  setFiltroAnomalie(
                    'nomePersonaVerificheAnomalie',
                    $event.name
                  );
                  resetTesserati();

                  inputFocus = null;
                "
              ></ListTesserati>
            </div>
          </div>
          <div class="text-center mt-5">
            <button
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                setFiltroAnomalie('avvioRicercaVerificheAnomalie', true);
                $emit('getVerificheAnomalieList');
                filtroAnomalieVerificheAnomalie.colonne_per_persona
                  ? setFiltroAnomalie(
                      'ricercaDopoSelezioneSelectAnomalie',
                      true
                    )
                  : setFiltroAnomalie(
                      'ricercaDopoSelezioneSelectAnomalie',
                      false
                    );
              "
              :disabled="!id_procedura_di_verificaVerificheAnomalie"
            >
              Cerca
              <i class="bi bi-search fs-6 text-gray-600"> </i>
            </button>
            <span
              type="button"
              data-bs-toggle=""
              data-bs-target=""
              aria-expanded="false"
              aria-controls=""
              class="badge rounded-pill bg-light text-gray-600 ms-4"
              @click="
                $emit('resetFilters');
                resetCategorie();
                resetQualfiche();
                setFiltroAnomalie('avvioRicercaVerificheAnomalie', false);
                rerender++;
              "
            >
              <i class="bi bi-arrow-clockwise fs-6 text-gray-600"> </i
            ></span>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  computed,
  ref,
  watch,
  getCurrentInstance,
  onMounted,
} from "vue";
import { useStore } from "vuex";
import SelectInput from "@/components/components-fit/utility/inputs/SelectInput.vue";
import SelectInputEvento from "@/components/components-fit/utility/inputs/SelectInputEvento.vue";
import { format } from "@/composables/formatDate";
import "vue3-date-time-picker/dist/main.css";
import ListSocieta from "@/components/components-fit/utility/ListSocieta.vue";
import ListTesserati from "@/components/components-fit/utility/ListTesserati.vue";

export default defineComponent({
  name: "filtriAttestatiQualifiche",
  emits: ["resetFilters", "getVerificheAnomalieList"],
  props: {
    resetted: {
      type: Boolean,
    },
  },
  components: {
    SelectInput,
    ListSocieta,
    SelectInputEvento,
    ListTesserati,
  },
  setup(props) {
    const isActive = ref(true);
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;
    const albi_tipo = computed(() =>
      store.getters.getStateFromName("albi_tipo")
    );

    watch(
      () => props.resetted,
      (value) => {
        if (value) {
          // resetCategorie();
          // resetQualfiche();
        }
      }
    );

    const VerificheAnomalieLookup = computed(() =>
      store.getters.getStateFromName("procedure_di_verifica")
    );
    const discipline = computed(() =>
      store.getters.getStateFromName("discipline")
    );
    const comitati = computed(() => store.getters.getStateFromName("comitati"));
    const keysVerificheAnomalie = ref("pdv|c|d");
    if (!VerificheAnomalieLookup.value) {
      store.dispatch("setStoreData", {
        keys: { keys: keysVerificheAnomalie.value },
        apiLink: globalApi.COMBOLIST_GET,
      });
    }

    const setFiltroAnomalie = (nome_variabile, valore_variabile) => {
      store.commit("setFilterVerificheAnomalie", {
        nome_variabile,
        valore_variabile,
      });
    };

    const ricercaDopoSelezioneSelectAnomalie = computed(
      () => store.getters.ricercaDopoSelezioneSelectAnomalie
    );

    const getSocieta = (string) => {
      if (string === "") {
        store.commit("emptySocietaNomeCodAff");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getSocietaNomeCodAff", string);
    };
    const resetSocieta = () => {
      store.commit("emptySocietaNomeCodAff");
    };

    const getTesserati = (string) => {
      store.commit("setNomeTesseratoMovimenti", string);
      if (string === "") {
        store.dispatch("svuotaPersoneNomeCognome");
        return;
      } else if (string.length < 3) return;
      store.dispatch("getPersoneNomeCognome", string);
    };
    const spliceTesserati = () => {
      setId_personaVerificheAnomalie(null);
      setNomepersonaVerificheAnomalie("");
      resetTesserati();
    };
    const resetTesserati = () => {
      store.dispatch("svuotaPersoneNomeCognome");
    };

    const setId_personaVerificheAnomalie = (event) => {
      store.commit("setId_personaVerificheAnomalie", event);
    };
    const setNomepersonaVerificheAnomalie = (event) => {
      store.commit("setNomepersonaVerificheAnomalie", event);
    };

    const rerender = ref(0);

    return {
      rerender,
      resetSocieta,
      getSocieta,
      setFiltroAnomalie,
      filtroAnomalieVerificheAnomalie: computed(
        () => store.getters.filtroAnomalieVerificheAnomalie
      ),
      id_inviato: computed(() => store.getters.id_inviatoAttestatiQualifiche),
      id_scaricato: computed(() => store.getters.id_inviatoAttestatiQualifiche),

      tesseratiList: computed(() => store.getters.personeSocieta),
      nome_persona: computed(
        () => store.getters.nomePersonaAttestatiQualifiche
      ),

      isActive,

      societa: computed(() => store.getters.societaNomeCodAff),

      albi_tipo,
      format,
      loaded: computed(() =>
        store.getters.getStateFromName("loadedVERIFICA_ANOMALIE_LIST")
      ),

      VerificheAnomalieLookup,
      discipline,
      comitati,
      ricercaDopoSelezioneSelectAnomalie,
      id_procedura_di_verificaVerificheAnomalie: computed(
        () => store.getters.id_procedura_di_verificaVerificheAnomalie
      ),
      id_comitatoVerificheAnomalie: computed(
        () => store.getters.id_comitatoVerificheAnomalie
      ),
      id_societaVerificheAnomalie: computed(
        () => store.getters.id_societaVerificheAnomalie
      ),
      nome_societaVerificheAnomalie: computed(
        () => store.getters.nome_societaVerificheAnomalie
      ),
      id_disciplinaVerificheAnomalie: computed(
        () => store.getters.id_disciplinaVerificheAnomalie
      ),

      id_personaVerificheAnomalie: computed(
        () => store.getters.id_personaVerificheAnomalie
      ),
      nomePersonaVerificheAnomalie: computed(
        () => store.getters.nomePersonaVerificheAnomalie
      ),

      setNomepersonaVerificheAnomalie,
      setId_personaVerificheAnomalie,
      getTesserati,
      resetTesserati,
      id_persona: computed(() => store.getters.id_personaAttestatiQualifiche),
      spliceTesserati,
      avvioRicercaVerificheAnomalie: computed(
        () => store.getters.avvioRicercaVerificheAnomalie
      ),
    };
  },
  computed: {},
});
</script>

<style></style>
