<template>
  <div>
    <FiltriVerificheAnomalie
      @getVerificheAnomalieList="getVerificheAnomalieList"
      @resetFilters="resetFilters"
    />
    <TableVerificheAnomalie
      @getVerificheAnomalieList="getVerificheAnomalieList"
      @resetFilters="resetFilters"
    />
  </div>
</template>

<script>
import {
  defineComponent,
  onMounted,
  computed,
  getCurrentInstance,
  ref,
} from "vue";
import { useStore } from "vuex";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { trimInput } from "@/composables/trimInput";

import FiltriVerificheAnomalie from "@/components/verifiche-anomalie/FiltriVerificheAnomalie.vue";
import TableVerificheAnomalie from "@/components/verifiche-anomalie/TableVerificheAnomalie.vue";

export default defineComponent({
  name: "VerificheAnomalie-snm",
  components: {
    FiltriVerificheAnomalie,
    TableVerificheAnomalie,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Verifiche anomalie", []);
    });
    const store = useStore();

    const instance = getCurrentInstance();
    const globalApi =
      instance.appContext.config.globalProperties.$apiListGlobal;

    // const stagione = computed(() => store.getters.stagioneSelected);
    // const stagione = computed(() => store.getters.stagioneSelected);
    const rowsToSkip = computed(
      () => store.getters.rowsToSkipVerificheAnomalie
    );
    const fetchRows = computed(() => store.getters.fetchRowsVerificheAnomalie);
    const sortColumn = computed(
      () => store.getters.sortColumnVerificheAnomalie
    );
    const sortOrder = computed(() => store.getters.sortOrderVerificheAnomalie);
    const id_procedura_di_verifica = computed(
      () => store.getters.id_procedura_di_verificaVerificheAnomalie
    );
    const id_comitato = computed(
      () => store.getters.id_comitatoVerificheAnomalie
    );
    const id_societa = computed(
      () => store.getters.id_societaVerificheAnomalie
    );
    const id_disciplina = computed(
      () => store.getters.id_disciplinaVerificheAnomalie
    );
    const anno = computed(() => store.getters.stagioneSelected);
    const note_richiesta = computed(
      () => store.getters.note_richiestaVerificheAnomalie
    );
    const esportazione = computed(
      () => store.getters.esportazioneVerificheAnomalie
    );
    const id_persona = computed(
      () => store.getters.id_personaVerificheAnomalie
    );

    const getVerificheAnomalieList = () => {
      console.log("getVerificheAnomalieList");
      store.dispatch("setStoreListData", {
        keys: {
          id_procedura_di_verifica: id_procedura_di_verifica.value,
          id_comitato: id_comitato.value,
          id_societa: id_societa.value,
          id_disciplina: id_disciplina.value,
          anno: anno.value,
          id_persona: id_persona.value,
          note_richiesta: note_richiesta.value,
          esportazione: esportazione.value,
          rowsToSkip: rowsToSkip.value,
          fetchRows: fetchRows.value,
          sortColumn: sortColumn.value,
          sortOrder: sortOrder.value,
        },
        apiLink: globalApi.VERIFICA_ANOMALIE_LIST,
        itemName: "VERIFICA_ANOMALIE_LIST",
      });
    };
    const resetted = ref(false);
    // getVerificheAnomalieList();

    const resetFilters = () => {
      store.commit("resetFiltersVerificheAnomalie");
      resetted.value = true;
      setTimeout(() => {
        resetted.value = false;
      }, 1000);
      //getVerificheAnomalieList();
    };

    return {
      getVerificheAnomalieList,
      resetFilters,
      resetted,
    };
  },
});
</script>
